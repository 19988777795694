import React from "react";
import { useCurrentUser } from "./AuthProvider";
import Avatar from "./Avatar";
import { Companies } from "./Dashboard/Companies";
import { DashboardUpdates } from "./Dashboard/DashboardUpdates";
import { DataOverview } from "./Dashboard/DataOverview";
import { NetWorkCollaboration } from "./Dashboard/NetWorkCollaboration";
import LatestNews from "./LatestNews";

const Dashboard: React.FC = () => {
  const { user: currentUser } = useCurrentUser();

  return (
    <div>
      <div className="flex-col lg:overflow-hidden">
        <div className="flex h-[calc(100vh-7rem)] flex-col gap-3 lg:flex-row">
          <div className="flex flex-col gap-y-3 lg:w-2/3">
            <div className="flex h-full w-full flex-col gap-y-3 sm:flex-row sm:gap-x-3">
              <Companies />
              <DashboardUpdates />
            </div>
          </div>
          <div className="flex h-[calc(100vh-7rem)] min-w-[270px] flex-col gap-y-3 sm:flex-row sm:gap-y-0 sm:gap-x-3 lg:w-1/3 lg:flex-col lg:gap-x-0">
            <LatestNews />
            <NetWorkCollaboration />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
