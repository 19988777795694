import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import useSWR from "swr";
import { getter } from "../api";
import { News, Option, OrganizationStub, Success } from "../api/types";
import Avatar from "./Avatar";
import { NoResult } from "./NoResult";
import SpinnerCustom from "./SpinnerCustom";
import { dateFormatDDMMMYYYY, isValidJson } from "./Utils/commons";

const Tooltip = ({
  value,
  className,
}: {
  value: string;
  className?: string;
}) => {
  return (
    <div
      id="tooltip-bottom"
      role="tooltip"
      className={`absolute z-top ${className} tooltip invisible top-full inline-block w-max max-w-xs break-all rounded-lg bg-gray-900 py-1 px-2 text-xs font-medium text-white shadow-sm group-hover:visible`}
    >
      {value}
    </div>
  );
};

const LatestNews = () => {
  const [entitySelected, setEntitySelected] = useState<Option>({
    label: "All",
    value: "all",
  });

  const [entityOptions, setEntityOptions] = useState<Option[]>([]);

  const { data: vertexEntities } = useSWR<Success<OrganizationStub[]>>(
    `/api/people_map/organizations/vertex_entities`,
    getter,
    { revalidateOnFocus: false },
  );

  const { data: latest_news } = useSWR<Success<News[]>>(
    `/api/people_map/news?dashboard=True&limit=50${
      entitySelected.value === "all"
        ? ""
        : `&vertex_entity=${entitySelected.value}`
    }`,
    getter,
    { revalidateOnFocus: false },
  );

  useEffect(() => {
    if (vertexEntities && vertexEntities.data.length > 0) {
      setEntityOptions([
        ...[
          {
            label: "All",
            value: "all",
          },
        ],
        ...vertexEntities.data.map((item) => ({
          label: item.name,
          value: item.id,
        })),
      ]);
    }
  }, [vertexEntities]);

  useEffect(() => {
    const dashboardLatestNews =
      localStorage.getItem("dashboardLatestNews") ?? "";
    if (dashboardLatestNews.length > 0 && isValidJson(dashboardLatestNews)) {
      setEntitySelected(JSON.parse(dashboardLatestNews));
    }
  }, []);

  return (
    <div className="border-1 h-full overflow-hidden whitespace-normal rounded-lg border bg-white pt-0.5 pb-4 pl-4 !pr-0 shadow-sm sm:w-1/2 lg:h-[60%] lg:w-full">
      <div className="my-3 flex flex-row items-center justify-between">
        <div className="flex flex-row">
          <span className="mr-2 h-5 w-2 rounded-sm bg-blue-900"></span>
          <div className="mr-2 text-sm font-bold sm:text-xs md:text-sm">
            News
          </div>
        </div>
        <div className="relative mr-3">
          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button
                  className={`flex min-w-[100px] flex-row items-center justify-between gap-x-2 rounded-md bg-slate-100 px-3 py-2 ${open ? "" : "text-opacity-90"} focus:outline-none`}
                >
                  <div className="text-xs font-semibold text-gray-900">{`${entitySelected.label}`}</div>
                  <ChevronDownIcon className="h-4 w-4" />
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute top-10 right-0 z-top w-max">
                    {entityOptions.length > 0 && (
                      <div className="h-fit overflow-y-auto rounded-lg border border-gray-200 bg-white p-2 text-xs">
                        {entityOptions.map((item: Option) => (
                          <div
                            key={item.value}
                            className={`flex cursor-pointer items-center rounded py-2 px-4 ${
                              entitySelected.value === item.value
                                ? "bg-blue-900 text-white"
                                : "hover:bg-gray-100"
                            }`}
                            onClick={() => {
                              setEntitySelected(item);
                              localStorage.setItem(
                                "dashboardLatestNews",
                                JSON.stringify(item),
                              );
                              close();
                            }}
                          >
                            {item.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            )}
          </Popover>
        </div>
      </div>
      <div className="hover:show-scrollbar flex max-h-[88%] flex-col overflow-y-auto !overflow-x-hidden pr-6 sm:h-[92%]">
        {latest_news && latest_news.data ? (
          latest_news.data.length === 0 ? (
            <NoResult message="No News" />
          ) : (
            latest_news.data.map((latest_new: News) => (
              <div
                key={latest_new.id}
                className="border-b-[1px] border-gray-300 py-2"
              >
                <div className="flex flex-row items-center gap-x-2">
                  {latest_new.organizations.map((org) => (
                    <Link
                      href={`/organizations/${org.id}`}
                      key={org.id}
                      className="group relative"
                    >
                      <Avatar
                        name={org.name}
                        size={50}
                        url={org.image_url}
                        className="rounded-none shadow-sm"
                      />
                      <Tooltip value={org.name} className="left-0" />
                    </Link>
                  ))}
                  <Link
                    href={latest_new.url}
                    className="w-full"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="relative flex flex-col">
                      <span className="break-normal text-left text-xs font-semibold text-black line-clamp-2">
                        {latest_new.title}
                      </span>
                      <div className="flex flex-row justify-between text-2xs font-light text-black">
                        <div>{latest_new.source}</div>
                        <div>
                          {dateFormatDDMMMYYYY(latest_new.publish_date)}
                        </div>
                      </div>
                      <Tooltip
                        value={latest_new.url}
                        className="left-1/2 right-1/2 -translate-x-1/2"
                      />
                    </div>
                  </Link>
                </div>
              </div>
            ))
          )
        ) : (
          <SpinnerCustom />
        )}
      </div>
    </div>
  );
};

export default LatestNews;
